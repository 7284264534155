import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import Select from "react-select";

import { useAnalyticsSelectTheme } from "../../useAnalyticsSelectTheme";
import { StepWrapper } from "./SkillsReportBuilderStepOne";

type StepTwoSkill = {
  id: string;
  name: string;
  description: string;
};

export interface SkillsReportBuilderStepTwoFormData {
  source: string;
  skills: StepTwoSkill[];
}

type StepTwoProps = {
  onComplete: (data: SkillsReportBuilderStepTwoFormData) => void;
  onCancel: () => void;
};

const SkillsReportBuilderStepTwo: React.FC<StepTwoProps> = ({
  onComplete,
  onCancel,
}) => {
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const defaultValues = {
    source: "",
    skills: [{ id: "1", name: "", description: "" }],
  };
  const { control, register, handleSubmit, setValue } =
    useForm<SkillsReportBuilderStepTwoFormData>({
      defaultValues,
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  const onSubmit = (formData: SkillsReportBuilderStepTwoFormData): void => {
    const { source, skills } = formData;
    onComplete({ source, skills });
  };

  // TODO: add more options (and probably an enum for communication with the backend)
  const sourceOptions = [{ value: "", label: "Custom (w/ AI assistant)" }];

  const { container: containerStyles = {}, ...selectStylesRest } = selectStyles;
  const commonSelectStyles = {
    container: (provided: Record<string, any>) => ({
      ...provided,
      flex: 1,
      minWidth: 120,
      ...containerStyles,
    }),
    ...selectStylesRest,
  };

  return (
    <StepWrapper>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontSize="2xl" color="gray.900" fontWeight="600">
          Report builder
        </Text>
        <Button
          variant="solid"
          size="sm"
          fontWeight="500"
          disabled={fields.length === 0}
          onClick={handleSubmit(onSubmit)}
        >
          Run report
        </Button>
      </Flex>
      <Divider my="6" borderColor="gray.200" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl maxWidth="300px">
          <FormLabel color="gray.600" mb="1">
            Skills source
          </FormLabel>
          <Select
            {...register("source")}
            styles={commonSelectStyles}
            theme={selectTheme}
            options={sourceOptions}
            value={sourceOptions.find((o) => o.value === defaultValues.source)}
            onChange={(option) => setValue("source", option?.value || "")}
          />
        </FormControl>
        <Text mt="5" mb="2" color="gray.600" fontWeight="400" fontSize="sm">
          Skills
        </Text>
        <VStack
          spacing="4"
          alignItems="flex-start"
          backgroundColor="gray.50"
          py="5"
          px="4"
          borderRadius="12px"
          mb="8"
        >
          {fields.map((field, index) => (
            <Flex
              flexDir="row"
              alignItems="flex-start"
              justifyItems="flex-start"
              width="100%"
              key={field.id}
            >
              <Input
                width="280px"
                mr="2"
                placeholder="Skill"
                required
                _placeholder={{ color: "gray.600" }}
                {...register(`skills.${index}.name`)}
              />
              <Flex mr="2" flexGrow="1">
                <Input
                  required
                  placeholder="Optional description or examples"
                  _placeholder={{ color: "gray.400" }}
                  {...register(`skills.${index}.description`)}
                />
              </Flex>
              <IconButton
                aria-label="remove skill"
                type="button"
                // colorScheme="red"
                variant="icon"
                backgroundColor="transparent"
                size="sm"
                icon={<Icon as={HiOutlineTrash} boxSize="5" />}
                onClick={() => remove(index)}
              />
            </Flex>
          ))}
          <Button
            aria-label="add skill"
            variant="ghost"
            fontWeight="500"
            size="sm"
            leftIcon={<Icon as={HiOutlinePlus} boxSize="5" />}
            onClick={() => {
              append({
                id: `${fields.length + 1}`,
                name: "",
                description: "",
              });
            }}
          >
            Add skill
          </Button>
        </VStack>
      </form>
      <Divider borderColor="gray.200" />
      <Flex flexDir="row" justifyContent="space-between" mt="6">
        <Button variant="ghost" fontWeight="500" size="sm" onClick={onCancel}>
          Back
        </Button>
        <Button
          variant="solid"
          size="sm"
          fontWeight="500"
          onClick={handleSubmit(onSubmit)}
          disabled={fields.length === 0}
        >
          Run report
        </Button>
      </Flex>
    </StepWrapper>
  );
};

export default SkillsReportBuilderStepTwo;
