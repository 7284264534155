import { Box, Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Navigate } from "react-router-dom";

import { BetaTag, LoadingIndicator } from "../../../../components";
import { UserRoleName } from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import AnalyticsBarChart, {
  AnalyticsBarChartData,
} from "../bar-chart/AnalyticsBarChart";
import AnalyticsSkillsReportTable from "./AnalyticsSkillsReportTable";

const AnalyticsSkillsReport: React.FC = () => {
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  // TODO: Use actual data in UI
  // TODO: Add pagination to table
  // TODO: Add sorting to table

  // Dummy values
  const chartLoading = false;
  const chartCounts: AnalyticsBarChartData = [
    { key: "1", label: "Skill 1", value: 10 },
    { key: "2", label: "Skill 2", value: 20 },
    { key: "3", label: "Skill 3", value: 30 },
    { key: "4", label: "Skill 4", value: 40 },
    { key: "5", label: "Skill 5", value: 50 },
  ];

  const tableLoading = false;
  const tableData = [
    {
      candidateName: "Candidate 1",
      numInterviews: 5,
      skills: [
        { skillName: "Skill 1", skillCovered: true },
        { skillName: "Skill 2", skillCovered: true },
        { skillName: "Skill 3", skillCovered: true },
        { skillName: "Skill 4", skillCovered: false },
        { skillName: "Skill 5", skillCovered: true },
      ],
    },
    {
      candidateName: "Candidate 2",
      numInterviews: 3,
      skills: [
        { skillName: "Skill 1", skillCovered: true },
        { skillName: "Skill 2", skillCovered: false },
        { skillName: "Skill 3", skillCovered: true },
        { skillName: "Skill 4", skillCovered: false },
        { skillName: "Skill 5", skillCovered: true },
      ],
    },
    {
      candidateName: "Candidate 3",
      numInterviews: 2,
      skills: [
        { skillName: "Skill 1", skillCovered: false },
        { skillName: "Skill 2", skillCovered: false },
        { skillName: "Skill 3", skillCovered: true },
        { skillName: "Skill 4", skillCovered: false },
        { skillName: "Skill 5", skillCovered: false },
      ],
    },
  ];

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        h={16}
        px={8}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <HStack spacing={2} align="baseline">
          <Text fontSize="xl" fontWeight="500" color="gray.800">
            Skills Coverage
          </Text>
          <BetaTag bg="purple.300" _hover={{ bg: "purple.400" }} />
          <Button
            variant="link"
            fontSize="sm"
            fontWeight={500}
            // onClick={() => {}}
          >
            Details
          </Button>
        </HStack>
        <Button
          colorScheme="blue"
          size="sm"
          // onClick={() => {}}
        >
          New report
        </Button>
      </Flex>
      <Flex
        flexDir="row"
        px={8}
        py={4}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Text as="span" fontWeight="500">
          Account Executive | Completed all interviews | Last 30 days
        </Text>
      </Flex>
      <Flex flexDir="column" p={8} pt={12}>
        <VStack spacing={6}>
          <Flex direction="column" alignSelf="start">
            <Text fontSize="3xl" fontWeight={400}>
              How consistently are key interview topics being covered?
            </Text>
            <Text fontWeight={400} color="gray.400">
              Identify which topics are discussed during interviews across all
              candidates for one or more positions.
            </Text>
          </Flex>
          <ReportSection>
            <ReportSectionHeader
              header="How often a skill was discussed across candidates’ interviews"
              date="Jan 24 - Feb 23"
              subheader="16 candidates • 74 interviews • Account Executive"
            />
            <Box w="100%" py="6">
              {chartLoading && <LoadingIndicator />}
              {!chartLoading && !!chartCounts.length && (
                <AnalyticsBarChart data={chartCounts} margin={{ left: 0 }} />
              )}
            </Box>
          </ReportSection>
          <ReportSection>
            <ReportSectionHeader
              header="How often a skill was discussed across candidates’ interviews"
              date="Jan 24 - Feb 23"
              subheader="16 candidates • 74 interviews • Account Executive"
            />
            <Box mt="3" width="100%">
              {tableLoading && <AnalyticsReportTableSkeleton />}
              {!!tableData.length && !tableLoading && (
                <AnalyticsSkillsReportTable
                  // currentTopic={queryConfig.topic.value}
                  tableData={tableData}
                  skills={chartCounts.map((c) => c.label)}
                />
              )}
            </Box>
          </ReportSection>
        </VStack>
      </Flex>
    </>
  );
};

const ReportSection: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <VStack
      spacing={6}
      w="100%"
      p={6}
      pt={5}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="xl"
    >
      {children}
    </VStack>
  );
};

const ReportSectionHeader: React.FC<{
  header: string;
  date: string;
  subheader: string;
}> = ({ header, date, subheader }) => {
  return (
    <Flex w="100%" direction="column">
      <Flex w="100%" direction="row" align="center" justify="space-between">
        <Text fontSize="lg" fontWeight={600}>
          {header}
        </Text>
        <Text fontSize="sm" fontWeight={400} color="gray.400" ml={2}>
          {date}
        </Text>
      </Flex>
      <Text fontSize="sm" fontWeight={400} color="gray.600">
        {subheader}
      </Text>
    </Flex>
  );
};

export default AnalyticsSkillsReport;
