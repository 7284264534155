import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import Select from "react-select";

import { SelectDateRangeState } from "../../../../../components/SelectDateRange/SelectDateRange";
import { useOrgPositionsQuery } from "../../../../graphql";
import AnalyticsDateSelect from "../../AnalyticsDateSelect";
import { useAnalyticsSelectTheme } from "../../useAnalyticsSelectTheme";

type StepOneProps = {
  onComplete: () => void;
  onCancel: () => void;
  positionId?: string;
  onPositionIdChange: (positionId: string) => void;
  candidateFilter?: string;
  onCandidateFilterChange: (candidateFilter: string) => void;
  dateRange: SelectDateRangeState;
  onDateRangeChange: (dateRange: SelectDateRangeState) => void;
};

const SkillsReportBuilderStepOne: React.FC<StepOneProps> = ({
  onComplete,
  onCancel,
  positionId,
  onPositionIdChange,
  candidateFilter,
  onCandidateFilterChange,
  dateRange,
  onDateRangeChange,
}) => {
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();

  const onSubmit = (): void => {
    onComplete();
  };

  const { data: positionData, loading: positionsLoading } =
    useOrgPositionsQuery({});

  const positions =
    positionData?.currentUser?.organization.positions.results || [];
  const positionOptions = useMemo(() => {
    const sorted = [...positions].sort((a, b) =>
      a.displayTitle.localeCompare(b.displayTitle)
    );
    return sorted.map((position) => ({
      value: position.id,
      label: position.displayTitle,
    }));
  }, [positions]);

  const handleDateRangeChange = (newValue?: SelectDateRangeState): void => {
    if (!newValue) return;
    onDateRangeChange(newValue);
  };

  // TODO: add more options (and probably an enum for communication with the backend)
  const candidateFilterOptions = [
    { value: "any", label: "Any number of interviews" },
  ];

  const { container: containerStyles = {}, ...selectStylesRest } = selectStyles;
  const commonSelectStyles = {
    container: (provided: Record<string, any>) => ({
      ...provided,
      flex: 1,
      minWidth: 120,
      ...containerStyles,
    }),
    ...selectStylesRest,
  };

  const isValid = !!positionId && !!candidateFilter;
  return (
    <StepWrapper>
      <Text fontSize="2xl" color="gray.900" fontWeight="600">
        Report builder
      </Text>
      <Divider my="6" borderColor="gray.200" />
      <VStack spacing="6">
        <Text color="gray.800" fontWeight="400">
          The{" "}
          <Text as="span" fontWeight="600">
            Skills Coverage
          </Text>{" "}
          report lets you see how consistently your team is covering skills you
          define, during interviews across candidates for a position.
        </Text>
        <form onSubmit={onSubmit}>
          <VStack spacing="6" width="400px">
            <FormControl>
              <FormLabel color="gray.600" mb="1">
                Position
              </FormLabel>
              <Select
                onChange={(option) => onPositionIdChange(option?.value)}
                styles={commonSelectStyles}
                theme={selectTheme}
                value={positionOptions.find((o) => o.value === positionId)}
                options={positionOptions}
                isLoading={positionsLoading}
                placeholder={
                  positionsLoading ? "Loading positions..." : "Select..."
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel color="gray.600" mb="1">
                Include candidates who have completed...
              </FormLabel>
              <Select
                styles={commonSelectStyles}
                theme={selectTheme}
                options={candidateFilterOptions}
                value={candidateFilterOptions.find(
                  (o) => o.value === candidateFilter
                )}
                onChange={(option) => {
                  if (!option?.value) return;
                  onCandidateFilterChange(option.value);
                }}
              />
            </FormControl>
            <FormControl pb="4">
              <FormLabel color="gray.600" mb="1">
                Date range
              </FormLabel>
              <AnalyticsDateSelect
                state={dateRange}
                onSelect={handleDateRangeChange}
              />
            </FormControl>
          </VStack>
        </form>
        <Divider borderColor="gray.200" />
      </VStack>
      <Flex flexDir="row" justifyContent="space-between" mt="6">
        <Button variant="ghost" fontWeight="500" size="sm" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="solid"
          size="sm"
          fontWeight="500"
          onClick={onSubmit}
          disabled={!isValid}
        >
          Next
        </Button>
      </Flex>
    </StepWrapper>
  );
};

export const StepWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Flex
    flexDir="column"
    bg="white"
    borderRadius="12px"
    borderColor="gray.100"
    borderWidth="1px"
    px="8"
    pt="6"
    pb="8"
    width="875px"
    margin="0 auto"
    boxShadow="0px 1px 2px 0px #0000000F; 0px 1px 3px 0px #0000001A;"
  >
    {children}
  </Flex>
);

export default SkillsReportBuilderStepOne;
