import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { HiArrowDownTray } from "react-icons/hi2";
import { Navigate } from "react-router-dom";

import { LoadingIndicator } from "../../../../components";
import AILoading from "../../../../components/LoadingIndicator/AILoading";
import { formatISODate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { snakeCaseToSentenceCase } from "../../../../utils/string";
import {
  CandidateQuestionTopic,
  useAnalyticsCandidateQuestionKeyThemesQuery,
  useAnalyticsCandidateQuestionTopicCountsQuery,
  useAnalyticsCandidateQuestionTopicExamplesCsvLazyQuery,
  useAnalyticsCandidateQuestionTopicExamplesQuery,
  UserRoleName,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsDateSelect from "../AnalyticsDateSelect";
import AnalyticsFilters from "../AnalyticsFilters";
import AnalyticsInfoAlert from "../AnalyticsInfoAlert";
import { ReportContainer, ResultHeader } from "../AnalyticsReport";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import AnalyticsBarChart, {
  AnalyticsBarChartData,
} from "../bar-chart/AnalyticsBarChart";
import LabeledChartSelect from "../LabeledChartSelect";
import ToggleFiltersButton from "../ToggleFiltersButton";
import ToggleFiltersPanel from "../ToggleFiltersPanel";
import { CommonQueryVariables } from "../types";
import AnalyticsCandidateQuestionTopicsTable from "./AnalyticsCandidateQuestionsTable";
import useCandidateQuestionsConfig from "./useCandidateQuestionsConfig";

const AnalyticsCandidateQuestions: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  const [showFilters, setShowFilters] = useState(true);
  const [filterHeights, setFilterHeights] = useState<{ [key: string]: number }>(
    {
      defaultHeight: 50,
    }
  );
  const queryConfig = useCandidateQuestionsConfig();
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;

  const queryVariables: CommonQueryVariables = {
    dateRangeStart: formatISODate(queryConfig.dateRange.value.start),
    dateRangeEnd: formatISODate(queryConfig.dateRange.value.end),
    ...queryConfig.filters,
  };

  const { data: chartResult, loading: chartLoading } =
    useAnalyticsCandidateQuestionTopicCountsQuery({
      variables: queryVariables,
    });
  const chartData = chartResult?.candidateQuestionTopicCounts?.counts;
  const callCount = chartResult?.candidateQuestionTopicCounts?.totalCalls || 0;
  const candidateCount =
    chartResult?.candidateQuestionTopicCounts?.totalCandidates || 0;
  const sampleSize = `Sample: ${callCount} interviews with ${candidateCount} candidates.`;

  const chartCounts = useMemo(() => {
    if (!chartData) return [];
    const mappedTopics = chartData
      .map((o) => {
        const { topic, count } = o;
        if (typeof count !== "number") return null;
        return {
          key: topic,
          label: snakeCaseToSentenceCase(topic),
          value: Number(count.toFixed(1)),
        };
      })
      .filter(Boolean) as AnalyticsBarChartData;
    return mappedTopics;
  }, [chartData]);

  const questionTopicSelectOptions = useMemo(() => {
    const options = chartCounts
      .filter((count) => count.value > 0)
      .map((count) => ({
        value: count.key,
        label: count.label,
      }));
    options.unshift({ value: "ALL", label: "All categories" });
    return options;
  }, [chartCounts]);

  const { loading: tableLoading, data: tableResult } =
    useAnalyticsCandidateQuestionTopicExamplesQuery({
      variables: {
        ...queryVariables,
        ...(queryConfig.questionTopic.value !== "ALL" && {
          questionTopic: queryConfig.questionTopic.value,
        }),
      },
      skip: !queryConfig.questionTopic.value,
    });
  const tableData = tableResult?.candidateQuestionTopicExamples?.data || [];
  const tableReady =
    !tableLoading && !!tableData.length && queryConfig.questionTopic.value;

  const { data: summaryResult, loading: summaryLoading } =
    useAnalyticsCandidateQuestionKeyThemesQuery({
      variables: {
        predictionIds: tableData.map((row) => row.exampleId),
        questionTopic: queryConfig.questionTopic
          .value as CandidateQuestionTopic,
      },
      skip: !tableData.length || queryConfig.questionTopic.value === "ALL",
    });
  const summary = summaryResult?.candidateQuestionKeyThemes?.data || [];

  const [fetchCsv, { loading: csvLoading }] =
    useAnalyticsCandidateQuestionTopicExamplesCsvLazyQuery({
      fetchPolicy: "network-only",
      onCompleted(data) {
        if (data?.candidateQuestionTopicExamplesCsv?.url) {
          const url = data?.candidateQuestionTopicExamplesCsv?.url;
          const downloadLink = document.createElement("a");
          downloadLink.style.display = "none";
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      },
    });

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  const showSummarySection =
    queryConfig.questionTopic.value !== "ALL" && !tableLoading;

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            Candidate Question Trends
          </Text>
        </Flex>
        <Flex ml="auto">
          <ToggleFiltersButton
            open={showFilters}
            toggleFilters={() => {
              sendGAEvent(
                `filters_${!showFilters ? "open" : "closed"}`,
                "analytics"
              );
              setShowFilters((state) => !state);
            }}
          />
          <Flex minW="148px">
            <AnalyticsDateSelect
              state={queryConfig.dateRange.value}
              onSelect={queryConfig.dateRange.setValue}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex my="4">
        <ToggleFiltersPanel
          showFilters={showFilters}
          filterHeights={filterHeights}
          flex="1"
          mt="4"
          mb="0"
        >
          <AnalyticsFilters
            queryConfig={queryConfig}
            queryVariables={queryVariables}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
          />
        </ToggleFiltersPanel>
      </Flex>
      {queryConfig.dateRange.value.start < new Date("2024-05-01") && (
        <AnalyticsInfoAlert status="info" mt="-3" mb="4">
          Data is unavailable for candidate question topics before May 1st,
          2024.
        </AnalyticsInfoAlert>
      )}
      <ReportContainer>
        <ResultHeader
          headerText="Question Category Breakdown"
          captionText={
            chartLoading
              ? undefined
              : `Question categories are calculated as the percentage of candidates who asked them. ${sampleSize}`
          }
        >
          <Text
            color="gray.600"
            fontWeight="400"
            fontSize="sm"
            whiteSpace="nowrap"
          >
            {queryConfig.dateRange.displayValue}
          </Text>
        </ResultHeader>
        <Box>
          {chartLoading && <LoadingIndicator />}
          {!chartLoading && !!chartCounts.length && (
            <AnalyticsBarChart data={chartCounts} />
          )}
        </Box>
      </ReportContainer>
      <ReportContainer mt="6">
        <Flex flexDir="row" justifyContent="space-between" alignItems="start">
          <Flex flexDir="row" alignItems="center" mr="4">
            <Text fontSize="lg" fontWeight="600" color="gray.800" mr="4">
              Question Category Examples
            </Text>
            <LabeledChartSelect
              data-testid="analytics-candidate-question-trends--topic-menu"
              flexDir="row"
              alignItems="center"
              label=""
              singleSelect={{
                ...queryConfig.questionTopic,
                options: questionTopicSelectOptions,
                disabled: chartLoading,
              }}
              customSelectStyles={{
                container: (provided: any) => ({ ...provided, width: "250px" }),
              }}
            />
          </Flex>
          <Tooltip label="Download full CSV results">
            <IconButton
              aria-label="Download full CSV results"
              icon={<Icon as={HiArrowDownTray} boxSize="5" />}
              isLoading={csvLoading || tableLoading}
              variant="ghost"
              onClick={() => {
                sendGAEvent(
                  "download_candidate_question_topic_results",
                  "analytics"
                );
                fetchCsv({
                  variables: {
                    ...queryVariables,
                    ...(queryConfig.questionTopic.value !== "ALL" && {
                      questionTopic: queryConfig.questionTopic.value,
                    }),
                  },
                });
              }}
              onFocus={(e) => e.preventDefault()}
              hidden={!tableLoading && !tableData.length}
            />
          </Tooltip>
        </Flex>
        <Box mt="3" width="100%">
          {showSummarySection && (
            <>
              <Text
                mb="2"
                color="gray.500"
                fontWeight="600"
                textTransform="uppercase"
                fontSize="xs"
              >
                Key Themes
              </Text>
              <Box bg="gray.50" p="3" mb="6" borderRadius="8px">
                {summaryLoading && (
                  <Center>
                    <AILoading style={{ width: 100, height: 100 }} />
                  </Center>
                )}
                {!summaryLoading && summary && summary.length === 0 && (
                  <Text color="gray.500" fontSize="sm" fontWeight="400">
                    No key themes for the selected data.
                  </Text>
                )}
                {!summaryLoading &&
                  summary &&
                  summary.map((theme) => (
                    <Box key={theme.id} mb="3" _last={{ mb: 0 }}>
                      <Text
                        as="div"
                        fontSize="sm"
                        fontWeight="600"
                        color="gray.800"
                        mb="1"
                      >
                        {theme.themeName}
                      </Text>
                      <Text color="gray.800" fontSize="sm" fontWeight="400">
                        {theme.summary}
                      </Text>
                    </Box>
                  ))}
              </Box>
            </>
          )}
          {(chartLoading || tableLoading) && <AnalyticsReportTableSkeleton />}
          {tableReady && (
            <AnalyticsCandidateQuestionTopicsTable tableData={tableData} />
          )}
        </Box>
      </ReportContainer>
    </>
  );
};

export default AnalyticsCandidateQuestions;
