import { Flex, Icon, Text, Tooltip as ChakraTooltip } from "@chakra-ui/react";
import React from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

import { BetaTag } from "../../../../components";

const AskHeader = (): JSX.Element => (
  <Flex alignItems="center">
    <Text fontWeight="600" color="gray.800" mr={1} ml={1}>
      Ask assistant
    </Text>
    <ChakraTooltip
      bg="white"
      p="2"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      color="gray.800"
      boxShadow="none"
      fontSize="sm"
      shouldWrapChildren
      fontWeight="normal"
      placement="top"
      label={
        <>
          Assistant responses are generated using AI and may contain
          inaccuracies or errors. Use the source interview recordings to verify
          any statements made. <br />
          <br />
          The AI-powered assistant is unable to provide subjective judgements.
          It is designed to provide you information based on interview data that
          you have access to, which can help you make informed decisions your
          candidates.
        </>
      }
    >
      <Icon
        boxSize={4}
        color="gray.500"
        as={HiOutlineInformationCircle}
        mr={2}
        display="span"
        mb="1px"
      />
    </ChakraTooltip>
    <BetaTag bg="purple.300" _hover={{ bg: "purple.300" }} />
  </Flex>
);

export default AskHeader;
