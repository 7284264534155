import React, { useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";

import { usePageTracker } from "../../../utils/googleAnalytics";
import {
  canViewAdvancedInsights,
  canViewAnalyticsOverview,
  canViewMyInsightsForInterviewer,
  canViewOrgInsights,
} from "../../../utils/permissions";
import { AtsDataState, MetricName, useAtsDataStateQuery } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  AnalyticsPageLayout,
  AnalyticsSidebarType,
} from "./AnalyticsPageLayout";
import useEnabledMetrics from "./useEnabledMetrics";

const AnalyticsPage: React.FC = () => {
  usePageTracker("analytics");
  const currentUser = useCurrentUser();

  const [selectedOrgId, setSelectedOrgId] = useState<string>(
    currentUser.organization.id
  );
  const [showBHDemoDashboard, setShowBHDemoDashboard] = useState<boolean>(true);

  const { metric: metricParam, interviewerId: interviewerIdParam } = useParams<{
    metric: string;
    interviewerId: string;
  }>();
  const metric = metricParam?.toUpperCase() as MetricName;
  const enabledMetrics = useEnabledMetrics();
  const overviewEnabled = canViewAnalyticsOverview(enabledMetrics);
  const myInsightsEnabled = useFeatureFlag("analytics:my-insights");
  const overviewRefreshEnabled = useFeatureFlag("analytics:overview");
  const topicTrendsEnabled = useFeatureFlag("analytics:topic-trends");
  const candidateMotivationsEnabled = useFeatureFlag(
    "analytics:candidate-motivations"
  );
  const candidateQuestionsEnabled = useFeatureFlag(
    "analytics:candidate-questions"
  );
  const skillsReportEnabled = useFeatureFlag("analytics:skills-report");
  const advancedInsightsEnabled =
    canViewAdvancedInsights(currentUser) &&
    (topicTrendsEnabled ||
      candidateMotivationsEnabled ||
      candidateQuestionsEnabled ||
      skillsReportEnabled);

  const location = useLocation();
  const isMyInsights = location.pathname.includes("insights/interviewer");
  const isOverview = location.pathname.includes("overview");
  const isSkillsReport = location.pathname.includes("skills-report");
  const isAdvancedInsights =
    location.pathname.includes("topic-trends") ||
    location.pathname.includes("candidate-motivations") ||
    location.pathname.includes("candidate-questions") ||
    isSkillsReport;
  const sidebarEnabled = !isOverview && !isMyInsights;

  const atsDataState =
    useAtsDataStateQuery().data?.atsDataState || AtsDataState.DataNotAvailable;

  let sidebarType: AnalyticsSidebarType = sidebarEnabled ? "default" : "none";
  if (advancedInsightsEnabled && isAdvancedInsights) {
    sidebarType = "advanced";
  }

  if (!canViewOrgInsights(currentUser)) {
    if (
      !(
        isMyInsights &&
        myInsightsEnabled &&
        canViewMyInsightsForInterviewer(currentUser, interviewerIdParam)
      )
    ) {
      return <Navigate to="/" replace />;
    }
  }

  if (isOverview && !overviewEnabled) {
    return <Navigate to="/" replace />;
  }

  if (metric && !enabledMetrics.includes(metric)) {
    if (overviewEnabled) {
      return <Navigate to="/insights/overview" replace />;
    }
    return <Navigate to="/" replace />;
  }

  if (isAdvancedInsights && !advancedInsightsEnabled) {
    return <Navigate to="/" replace />;
  }

  let bgColor = isOverview && !overviewRefreshEnabled ? "unset" : "white";
  if (location.pathname.includes("skills-report/build")) {
    bgColor = "rgba(35, 152, 251, 0.03)";
  }

  return (
    <AnalyticsPageLayout
      metric={metric || MetricName.Report}
      data-tour-id="analytics-page"
      bgColor={bgColor}
      maxW={metric ? undefined : isOverview ? "1920px" : "1200px"}
      noPadding={isSkillsReport}
      sidebarType={sidebarType}
      atsDataState={atsDataState}
    >
      <Outlet
        context={{
          selectedOrgId,
          setSelectedOrgId,
          showBHDemoDashboard,
          setShowBHDemoDashboard,
          atsDataState,
        }}
      />
    </AnalyticsPageLayout>
  );
};

export default AnalyticsPage;
