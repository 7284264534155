const baseRoute = "/sign-up";

export const SIGN_UP_ROUTES = {
  /**
   * Un-authenticated routes
   */

  /** First sign in page with OAuth + email options */
  START: `${baseRoute}/greenhouse`,
  /** Page to collect verification code sent to email */
  VERIFY_EMAIL: `${baseRoute}/verify-email`,
  /** Dead end when a user cannot log in because there are no seats remaining */
  NO_SEATS_LEFT: `${baseRoute}/no-seats`,
  /** Dead end shown when the org does not allow self sign up */
  ERROR_NO_SELF_SIGN_UP: `${baseRoute}/error/self-sign-up`,

  /**
   * Authenticated routes
   */

  /**
   * Page to redirect to the appropriate sign in page, or forward
   * to the main app if no further setup is required
   */
  AUTH: `${baseRoute}/auth`,

  /**
   * First log-in routes
   */

  /** Page for first user to confirm org name */
  CONFIRM_ORG: `${baseRoute}/confirm-organization`,
  /** Page for subsequent user to claim a seat */
  CLAIM_SEAT: `${baseRoute}/claim-seats`,
  /** Confirmation page after creating user object */
  SIGNUP_COMPLETE: `${baseRoute}/complete`,

  /**
   * User onboarding routes
   */

  /** First route for user onboarding (collects name + persona) */
  USER_ONBOARDING_START: `${baseRoute}/onboarding`,
  /** Choose Zoom, Google Meet, Phone, etc. */
  SELECT_PLATFORM: `${baseRoute}/onboarding/select-platform`,
  /** Select calendar or greenhouse as source for what interviews to join */
  SELECT_SCHEDULING_TYPE: `${baseRoute}/onboarding/scheduling`,
  /**
   * Select options for what interviews to join. Varies based on scheduling
   * source selected in `SELECT_SCHEDULING_TYPE`.
   *
   * Requires a `type` url param to determine which scheduling page to show
   */
  SCHEDULING_SELECT_INTERVIEWS: `${baseRoute}/onboarding/scheduling/interviews`,
  /** Prompts user to install chrome extension */
  INSTALL_EXTENSION: `${baseRoute}/onboarding/extension`,
  /** User has completed all steps and can proceed to the main app */
  ONBOARDING_COMPLETE: `${baseRoute}/onboarding/done`,

  /**
   * Phone setup routes
   */

  /** Landing page for phone setup */
  PHONE_SETUP_START: `${baseRoute}/phone/setup`,
  /** Gathers phone number for verification */
  PHONE_SETUP_ENTER_PHONE: `${baseRoute}/phone/enter`,
  /** Displays verification code for user to enter */
  PHONE_SETUP_ENTER_CODE: `${baseRoute}/phone/code`,
  /** Allows user to select "mask my number" option */
  PHONE_SETUP_CALLER_ID: `${baseRoute}/phone/caller-id`,
  /** Shows info about how to call with BH */
  PHONE_SETUP_COMPLETE: `${baseRoute}/phone/complete`,

  /**
   * GH setup routes
   */

  /** Landing page for GH integration */
  GH_INTEGRATION_START: `${baseRoute}/greenhouse/integration`,
  GH_INTEGRATION_USER_PERMISSIONS: `${baseRoute}/greenhouse/user-permissions`,
  GH_INTEGRATION_DOMAIN_SETUP: `${baseRoute}/greenhouse/domain-setup`,
  GH_INTEGRATION_API_KEY_SETUP: `${baseRoute}/greenhouse/api-key-setup`,
  GH_INTEGRATION_VERIFY_CONNECTION: `${baseRoute}/greenhouse/verify-connection`,
  GH_INTEGRATION_BAD_PERMISSIONS: `${baseRoute}/greenhouse/bad-permissions`,
  GH_INTEGRATION_ASSIGN_ADMIN_USER: `${baseRoute}/greenhouse/assign-admin-user`,
  GH_INTEGRATION_CREATE_WEBHOOK: `${baseRoute}/greenhouse/create-webhook`,
  GH_INTEGRATION_DONE: `${baseRoute}/greenhouse/done`,
} as const;

export type SignUpState = keyof typeof SIGN_UP_ROUTES;
export type SignUpRoute = typeof SIGN_UP_ROUTES[SignUpState];
