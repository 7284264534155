import { Button, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import React from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi2";

import { CenteredCardLayout } from "../CenteredCardLayout";
import { Reload } from "../Icons";

interface Props {
  children: React.ReactNode;
  logoLinkUrl?: string;
  render?: React.ReactNode;
}

interface State {
  hasError: boolean;
  eventId: string | null;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error | null, errorInfo: React.ErrorInfo): void {
    Sentry.withScope((scope) => {
      scope.setExtra("componentStack", errorInfo.componentStack);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render(): React.ReactNode {
    const { hasError, eventId } = this.state;
    if (hasError) {
      if (this.props.render) {
        return this.props.render;
      }

      // render fallback UI
      return (
        <CenteredCardLayout
          cardProps={{ textAlign: "center" }}
          logoLinkUrl={this.props.logoLinkUrl}
        >
          <Flex alignItems="center" mt="8" mb="5" justifyContent="center">
            <Icon
              as={HiOutlineExclamationCircle}
              fill="red.500"
              color="white"
              boxSize="8"
              mr="2"
            />
            <Heading as="h3" fontSize="xl" fontWeight="semibold">
              Something went wrong
            </Heading>
          </Flex>
          <Text mb="10">
            We&apos;ve been notified of the error and are working to fix it.
            Please reload the page to continue.
          </Text>
          <Flex gap="3" flexWrap="wrap" justifyContent="center">
            <Button
              size="lg"
              px="4"
              h="10"
              lineHeight="6"
              fontWeight="medium"
              borderRadius="lg"
              leftIcon={<Reload boxSize="6" />}
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
            {eventId && (
              <Button
                size="lg"
                px="4"
                h="10"
                lineHeight="6"
                variant="outline"
                fontWeight="medium"
                borderRadius="lg"
                onClick={() => {
                  if (eventId) {
                    Sentry.showReportDialog({ eventId });
                  }
                }}
              >
                Report feedback
              </Button>
            )}
          </Flex>
        </CenteredCardLayout>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}
