import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

import ProgressStepper from "../../../../../components/ProgressStepper/ProgressStepper";
import { dateRange90DaysAgo } from "../../../../../components/SelectDateRange/SelectDateRange";
import {
  useNumberSearchParam,
  useSearchParam,
  useSelectDateRangeParams,
} from "../../../../../hooks/useSearchParam";
import { UserRoleName } from "../../../../graphql";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import SkillsReportBuilderStepOne from "./SkillsReportBuilderStepOne";
import SkillsReportBuilderStepTwo, {
  SkillsReportBuilderStepTwoFormData,
} from "./SkillsReportBuilderStepTwo";

const AnalyticsSkillsReportBuilder: React.FC = () => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const [builderStep, setBuilderStep] = useNumberSearchParam("step", 0);
  const [positionId, setPositionId] = useSearchParam("positionId", undefined);
  const [candidateFilter, setCandidateFilter] = useSearchParam(
    "candidateFilter",
    "any"
  );
  const [dateRange, setDateRange] = useSelectDateRangeParams(
    "dateRange",
    dateRange90DaysAgo()
  );

  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;
  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  const generateReport = (data: SkillsReportBuilderStepTwoFormData): void => {
    const { source, skills } = data;
    // TODO: call the mutation with { positionId, candidateFilter, dateRange, source, skills }
    navigate("/analytics/skills-report", {
      state: { positionId, candidateFilter, dateRange, source, skills },
    });
  };

  return (
    <Flex flexDir="column">
      <Flex
        flexDir="row"
        backgroundColor="white"
        borderColor="gray.200"
        borderBottomWidth="1px"
        px="8"
        py="4.5"
      >
        <Text mr="2" fontWeight="500" color="gray.800" fontSize="xl">
          Skills Coverage
        </Text>
        <Tag
          fontSize="xs"
          bgColor="purple.300"
          color="white"
          px="2"
          borderRadius="28px"
        >
          BETA
        </Tag>
      </Flex>
      <Box py="8" margin="0 auto">
        <ProgressStepper
          steps={["Basics", "Skills", "Done"]}
          activeStepIndex={builderStep as number}
          stepBgColor="#F5F9FD"
        />
      </Box>
      {builderStep === 0 && (
        <SkillsReportBuilderStepOne
          onCancel={() =>
            setBuilderStep(1)
          } /* TODO: figure out what clicking cancel does on the first screen */
          onComplete={() => setBuilderStep(1)}
          positionId={positionId}
          candidateFilter={candidateFilter}
          dateRange={dateRange}
          onPositionIdChange={setPositionId}
          onCandidateFilterChange={setCandidateFilter}
          onDateRangeChange={setDateRange}
        />
      )}
      {builderStep === 1 && (
        <SkillsReportBuilderStepTwo
          onCancel={() => setBuilderStep(0)}
          onComplete={(data) => generateReport(data)}
        />
      )}
    </Flex>
  );
};

export default AnalyticsSkillsReportBuilder;
